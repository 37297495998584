import {capitalRiseRouting} from "@/modules/genprox/modules/fund/modules/fundraising/capitalRise.routing";
import {capitalRouting} from "@/modules/genprox/modules/fund/modules/capital/capital.routing";
import {dashboardRouting} from "@/modules/genprox/modules/fund/modules/dashboard/dahboard.routing";
import {portfolioRouting} from "@/modules/genprox/modules/fund/modules/portfolio/portfolio.routing";
import {transactionsRouting} from "@/modules/genprox/modules/fund/modules/transactions/transactions.routing";
import {reportingRouting} from "@/modules/genprox/modules/reporting/reporting.routing";
import {RouteConfig} from "vue-router";
import {regReporting} from "@/modules/genprox/modules/fund/modules/reg-reporting/reg-reporting.routing";
import {accountingRouting} from "@/modules/accounting/accounting.routing";
import { whistleBlowerRouting } from "@/modules/genprox/modules/whistleblower/whistleblower.routing";
import { productsRouting } from "@/modules/genprox/modules/fund/modules/fundraising/modules/products/products.routing";
import { advisorsRouting } from "@/modules/genprox/modules/fund/modules/fundraising/modules/advisors/advisors.routing";
import { templatesRouting } from "@/modules/genprox/modules/fund/modules/fundraising/modules/templates/templates.routing"
import { statementsRouting } from "@/modules/statements/statements.routing";

const fundModule = () => import('./containers/FundModule.vue');
// const capitalRiseModule = () => import('./modules/fundraising/containers/CapitalRiseModule.vue');
const acceptPackage = () => import('../../containers/AcceptPackage.vue');
const chooseFundType = () => import('./components/add-new-fund/ChooseFundType.vue');
const selectFund = () => import('./components/add-new-fund/SelectFund.vue');
const addNewFund = () => import('./components/add-new-fund/AddNewFund.vue');
const addNewFundManager = () => import('./components/add-new-fund/AddNewFundManager.vue');
const pivotContainer = () => import('@/modules/genprox/containers/PivotContainer.vue');

import { REDIRECTED_PATH } from '@/modules/genprox/genprox.routing';
import store from "@/store/rootStore";

export const fundRouting: Array<RouteConfig> = [
    {
        path: 'fund',  /* /fund */
        name: 'fund',
        component: fundModule,
        children: [
            ...capitalRouting,
            ...dashboardRouting,
            ...portfolioRouting,
            ...transactionsRouting,
            ...reportingRouting,
            ...regReporting,
            ...accountingRouting,
            ...whistleBlowerRouting,
            ...productsRouting,
            ...capitalRiseRouting,
            ...advisorsRouting,
            ...templatesRouting,
            ...statementsRouting,
            {
                path: 'accept-package',
                component: acceptPackage,
                name: 'accept-package',
                redirect: '/'
            },
            {
                path: 'accept-package/:id',
                name: 'accept-package-by-id',
                component: acceptPackage, // TODO: Change this name plox
            },
            {
                path: 'choose-fund-type',
                name: 'choose-fund-type',
                component: chooseFundType,
            },
            {
                path: 'select-fund',
                name: 'select-fund',
                component: selectFund,
            },
            {
                path: 'new-fund',
                name: 'add-new-fund',
                component: addNewFund,
                props: true,
            },
            {
                path: 'new-fund-manager',
                name: 'new-fund-manager',
                component: addNewFundManager,
                props: true,
            },
            {
                path: 'analytics',
                name: 'fund-analytics',
                component: pivotContainer,
            },
            {
                path: 'analytics/:reportName',
                name: 'fund-analytics-report',
                component: pivotContainer,
                async beforeEnter(to, from, next) {
                    const activeUserData = store.getters['genprox/activeUserData'];
                    let pathName = window.location.href;
                    pathName = pathName?.replace(window.location.origin, '')

                    if (!activeUserData && pathName != '/auth/login') {
                        localStorage.setItem(REDIRECTED_PATH, pathName)
                    }

                    const contextData = store.getters['accounting/getContextData'];

                    if(to.query?.contextOwnerId) {
                        await store.dispatch('genprox/setUserContext', { id: to.query.contextOwnerId });
                        
                        if(!contextData?.id) {
                            const resp = await store.dispatch('genprox/getContextData');
                            store.commit('accounting/setContextData', resp);
                        }

                        const params = window?.location?.search
                        const queryParams = new URLSearchParams(params)
                        queryParams.delete('contextOwnerId')
                        const finalLink = `${to?.path}?${queryParams?.toString()}`
                        
                        next(finalLink)
                    } else {
                        next()
                    }
                },
            }
        ],
    },
];
