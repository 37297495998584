import {BACKEND_BASE_URL} from "@/shared/consts";
import {RouteConfig} from "vue-router";
import {accountingRouting} from "@/modules/accounting/accounting.routing";
import {capitalRiseRouting} from "@/modules/genprox/modules/fund/modules/fundraising/capitalRise.routing";
import {capitalRouting} from "@/modules/genprox/modules/fund/modules/capital/capital.routing";
import { whistleBlowerRouting } from "@/modules/genprox/modules/whistleblower/whistleblower.routing";
import { transactionsRouting } from "../fund/modules/transactions/transactions.routing";
import { productsRouting } from "../fund/modules/fundraising/modules/products/products.routing";
import { advisorsRouting } from "@/modules/genprox/modules/fund/modules/fundraising/modules/advisors/advisors.routing";
import { reportingRouting } from "../reporting/reporting.routing";
import { templatesRouting } from "@/modules/genprox/modules/fund/modules/fundraising/modules/templates/templates.routing"
import { statementsRouting } from "@/modules/statements/statements.routing"
import { portfolioRouting } from "../fund/modules/portfolio/portfolio.routing";
const companyModule = () => import('./containers/CompanyModule.vue');
const CompanyDashboard = () => import('./containers/CompanyDashboard.vue');
const pivotContainer = () => import('@/modules/genprox/containers/PivotContainer.vue');

import { REDIRECTED_PATH } from '@/modules/genprox/genprox.routing';
import store from "@/store/rootStore";

export const companyRouting: Array<RouteConfig> = [
    {
        path: 'company',
        name: 'portfolio-company',
        alias: 'portfolio-company',
        component: companyModule,
        children: [
            ...accountingRouting,
            ...whistleBlowerRouting,
            ...capitalRouting,
            ...transactionsRouting,
            ...productsRouting,
            ...reportingRouting,
            ...capitalRiseRouting,
            ...advisorsRouting,
            ...templatesRouting,
            ...statementsRouting,
            ...portfolioRouting,
            {
                path: 'dashboard',
                component: CompanyDashboard,
                name: 'company-dashboard',
            },
            {
                path: 'analytics',
                name: 'company-analytics',
                component: pivotContainer,
            },
            {
                path: 'analytics/:reportName',
                name: 'company-analytics-report',
                component: pivotContainer,
                async beforeEnter(to, from, next) {
                    const activeUserData = store.getters['genprox/activeUserData'];
                    let pathName = window.location.href;
                    pathName = pathName?.replace(window.location.origin, '')

                    if (!activeUserData && pathName != '/auth/login') {
                        localStorage.setItem(REDIRECTED_PATH, pathName)
                    }

                    const contextData = store.getters['accounting/getContextData'];

                    if(to.query?.contextOwnerId) {
                        await store.dispatch('genprox/setUserContext', { id: to.query.contextOwnerId });
                        
                        if(!contextData?.id) {
                            const resp = await store.dispatch('genprox/getContextData');
                            store.commit('accounting/setContextData', resp);
                        }

                        const params = window?.location?.search
                        const queryParams = new URLSearchParams(params)
                        queryParams.delete('contextOwnerId')
                        const finalLink = `${to?.path}?${queryParams?.toString()}`
                        
                        next(finalLink)
                    } else {
                        next()
                    }
                },
            }
        ]
    },
];
